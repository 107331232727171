import React, { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { DateTime } from 'luxon'

const formSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  start: Yup.string().required('Required'),
  end: Yup.string()
    .when('start', (start, schema) => {
      return (
        start &&
        schema.test(
          'date-compare',
          'End date should be greater than the start date',
          (value) => DateTime.fromISO(value) > DateTime.fromISO(start),
        )
      )
    })
    .required('Required'),
  venue_name: Yup.string().required('Required'),
  venue_address: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  organization: Yup.string().required('Required'),
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required'),
})

const FileUpload = (props) => {
  const { field, form } = props

  const handleChange = (event) => {
    const file = event.currentTarget.files[0]
    form.setFieldValue(field.name, file)
  }

  return (
    <label>
      <span>Image</span>
      <p className='help-text'>Please limit file size to 1 MB or below</p>

      <input
        type='file'
        onChange={(event) => handleChange(event)}
        accept='image/png, image/jpeg'
      />
    </label>
  )
}

const AssetForm = () => {
  const [authToken, setAuthToken] = useState('')
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setAuthToken(
      document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    )
  }, [])

  return (
    <>
      {success ? (
        <p className='alert-box success'>
          Thank you for submitting your free event to RAD. A member from the RAD
          team will review your submission and will contact you if any questions
          arise.
        </p>
      ) : (
        <Formik
          initialValues={{
            title: '',
            start: '',
            end: '',
            all_day: false,
            venue_name: '',
            venue_address: '',
            image: '',
            description: '',
            organization: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
          }}
          validationSchema={formSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true)

            let formData = new FormData()
            for (var key in values) {
              if (key === 'image') {
                if (values[key]) {
                  formData.append('file', values[key], values[key].name)
                }
              } else {
                formData.append(key, values[key])
              }
            }
            fetch('/events/create_draft.json', {
              method: 'post',
              headers: {
                'X-CSRF-TOKEN': authToken,
              },
              body: formData,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.success) {
                  setSuccess(true)
                } else {
                  action.setSubmitting(false)
                }
              })
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <div className='form-group'>
                <Field name='title'>
                  {({ field, meta }) => (
                    <label>
                      <span>
                        Event Title
                        {meta.touched && meta.error && (
                          <span className='error'>{meta.error}</span>
                        )}
                      </span>

                      <input
                        type='text'
                        {...field}
                        className={meta.touched && meta.error ? 'error' : ''}
                      />
                    </label>
                  )}
                </Field>
              </div>

              <div className='form-group'>
                <Field name='start'>
                  {({ field, meta }) => (
                    <label>
                      <span>
                        Start Time
                        {meta.touched && meta.error && (
                          <span className='error'>{meta.error}</span>
                        )}
                      </span>

                      <input
                        type={values.all_day ? 'date' : 'datetime-local'}
                        placeholder={
                          values.all_day ? 'mm/dd/yyyy' : 'mm/dd/yyyy, --:-- --'
                        }
                        {...field}
                        className={meta.touched && meta.error ? 'error' : ''}
                      />
                    </label>
                  )}
                </Field>
              </div>

              <div className='form-group'>
                <Field name='end'>
                  {({ field, meta }) => (
                    <label>
                      <span>
                        End Time
                        {meta.touched && meta.error && (
                          <span className='error'>{meta.error}</span>
                        )}
                      </span>

                      <input
                        type={values.all_day ? 'date' : 'datetime-local'}
                        placeholder={
                          values.all_day ? 'mm/dd/yyyy' : 'mm/dd/yyyy, --:-- --'
                        }
                        {...field}
                        className={meta.touched && meta.error ? 'error' : ''}
                      />
                    </label>
                  )}
                </Field>
              </div>

              <div className='form-group checkboxes'>
                <Field name='all_day'>
                  {({ field }) => (
                    <label className='checkbox'>
                      <input type='checkbox' {...field} />
                      <span>All Day Event</span>
                    </label>
                  )}
                </Field>
              </div>

              <div className='form-group'>
                <Field name='venue_name'>
                  {({ field, meta }) => (
                    <label>
                      <span>
                        Venue Name
                        {meta.touched && meta.error && (
                          <span className='error'>{meta.error}</span>
                        )}
                      </span>

                      <input
                        type='text'
                        {...field}
                        className={meta.touched && meta.error ? 'error' : ''}
                      />
                    </label>
                  )}
                </Field>
              </div>

              <div className='form-group'>
                <Field name='venue_address'>
                  {({ field, meta }) => (
                    <label>
                      <span>
                        Venue Address
                        {meta.touched && meta.error && (
                          <span className='error'>{meta.error}</span>
                        )}
                      </span>

                      <textarea
                        {...field}
                        rows={3}
                        className={meta.touched && meta.error ? 'error' : ''}
                      />
                    </label>
                  )}
                </Field>
              </div>

              <div className='form-group'>
                <Field name='image' component={FileUpload} />
              </div>

              <div className='form-group'>
                <Field name='description'>
                  {({ field, meta }) => (
                    <label>
                      <span>
                        Event Description
                        {meta.touched && meta.error && (
                          <span className='error'>{meta.error}</span>
                        )}
                      </span>

                      <textarea
                        {...field}
                        rows={6}
                        className={meta.touched && meta.error ? 'error' : ''}
                      />
                    </label>
                  )}
                </Field>
              </div>

              <h3>Contact Details</h3>

              <div className='form-group'>
                <Field name='organization'>
                  {({ field, meta }) => (
                    <label>
                      <span>
                        Asset/Organization Name
                        {meta.touched && meta.error && (
                          <span className='error'>{meta.error}</span>
                        )}
                      </span>

                      <input
                        type='text'
                        {...field}
                        className={meta.touched && meta.error ? 'error' : ''}
                      />
                    </label>
                  )}
                </Field>
              </div>

              <div className='form-group'>
                <div className='form-group--half'>
                  <Field name='first_name'>
                    {({ field, meta }) => (
                      <label>
                        <span>
                          First Name
                          {meta.touched && meta.error && (
                            <span className='error'>{meta.error}</span>
                          )}
                        </span>

                        <input
                          type='text'
                          {...field}
                          className={meta.touched && meta.error ? 'error' : ''}
                        />
                      </label>
                    )}
                  </Field>
                </div>

                <div className='form-group--half'>
                  <Field name='last_name'>
                    {({ field, meta }) => (
                      <label>
                        <span>
                          Last Name
                          {meta.touched && meta.error && (
                            <span className='error'>{meta.error}</span>
                          )}
                        </span>

                        <input
                          type='text'
                          {...field}
                          className={meta.touched && meta.error ? 'error' : ''}
                        />
                      </label>
                    )}
                  </Field>
                </div>
              </div>

              <div className='form-group'>
                <div className='form-group--half'>
                  <Field name='email'>
                    {({ field, meta }) => (
                      <label>
                        <span>
                          Email
                          {meta.touched && meta.error && (
                            <span className='error'>{meta.error}</span>
                          )}
                        </span>

                        <input
                          type='text'
                          {...field}
                          className={meta.touched && meta.error ? 'error' : ''}
                        />
                      </label>
                    )}
                  </Field>
                </div>

                <div className='form-group--half'>
                  <Field name='phone'>
                    {({ field, meta }) => (
                      <label>
                        <span>
                          Phone Number
                          {meta.touched && meta.error && (
                            <span className='error'>{meta.error}</span>
                          )}
                        </span>

                        <input
                          type='text'
                          {...field}
                          className={meta.touched && meta.error ? 'error' : ''}
                        />
                      </label>
                    )}
                  </Field>
                </div>
              </div>

              <button type='submit' disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default AssetForm
